<template>
    <div>
        <el-card>
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column label="表单名称" prop="name"></el-table-column>
                <el-table-column label="操作">
                    <template #default="{ row }">
                        <loading-button-vue :row="row"
                            v-if="$store.state.btn_if.indexOf(row.authority) != -1"></loading-button-vue>

                        <div v-else style="color: gray;">暂无权限</div>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- $store.state.btn_if.indexOf('article_category_list_edit') != -1 -->
    </div>
</template>

<script>
import loadingButtonVue from './loading-button.vue'
import list from '/src/mixin/list.js'
export default {
    mixins: [list],
    components: {
        loadingButtonVue,
    },
    data() {
        return {
            list: [
                {
                    url: '/admin/Enterprise/gameServiceFrom',
                    name: '游戏出海扬帆计划',
                    authority: 'game_service_form'
                },
                {
                    url: '/admin/Enterprise/adFrom',
                    name: '广告联盟',
                    authority: 'ad_form'

                },
                {
                    url: '/admin/Enterprise/projectFrom',
                    name: '全球创投生态联盟-我是创业者',
                    authority: 'project_form'
                },
                {
                    url: '/admin/Enterprise/organizationFrom',
                    name: '全球创投生态联盟-我是投资人',
                    authority: 'organization_form'
                },
                {
                    url: '/admin/Enterprise/enterpriseApplyFrom',
                    name: '出海商务通',
                    authority: 'enterprise_apply_form'
                },
            ],
        }
    },
    methods: {},
}
</script>

<style lang="less" scoped></style>