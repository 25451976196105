<template>
    <div>
        <el-button
            type="text"
            icon="el-icon-download"
            :loading="loading"
            size="mini"
            @click="exportForm"
            >导出表格</el-button
        >
    </div>
</template>

<script>
export default {
    props: {
        row: {
            type: [Object],
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async exportForm() {
            this.loading = true
            let { data } = await this.$http.post(this.row.url)
            this.loading = false
            if (data.data.url) {
                location.href = data.data.url
            } else {
                this.$message.error('未返回链接')
            }
        },
    },
}
</script>

<style lang="less" scoped>
</style>